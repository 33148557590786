<template>
	<div>
		<el-card v-if="!isauthorization">
			<div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-10d049b4-66a0-4a5a-82b1-c79836932126.png">
					<div class="info">
						<div class="tit">客户数据概览</div>
						<div class="tip">统计已经在企业内的客户数量、每日成员添加成功的好友数量、企业微信客户群数量等数据，可查看数据变化趋势。</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="jumpQiDian">授权安装企店助手</el-button>
				</div>
			</div>
			<div style="margin-top:25px">
				<div class="desc-title">1/客户数据概览</div>
				<img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210802-94545e4b-ff77-4a64-9932-7025b7406950.png"></img>
			
				<div class="desc-title">2/统计指标说明文案</div>
				<div class="desc-content">
					<div>（1）客户数：已经成为企业微信客户的人数，1个客户添加多个员工企业微信，计1个客户</div>
					<div>（2）新增好友关系：筛选时间范围内，员工企业微信和客户微信添加成为好友，计为新增好友关系，1个客户添加多个员工企业微信，计多个新增</div>
					<div>（3）流失好友关系：筛选时间范围内，客户删除员工企业微信的数量，计为流失好友关系，1个客户删除多个员工企业微信，计多个流失</div>
					<div>（4）净增好友关系：筛选时间范围内，新增好友关系数-流失好友关系数</div>
					<div>（5）全部客户群数量：已经创建的企业微信客户群，并且未解散的数量</div>
					<div>（6）群内客户总数：未解散的企业微信客户群中，群内成为是微信用户的客户数量</div>
					<div>（7）入群客户总数：筛选时间范围内，加入群聊的微信用户数量</div>
					<div>（8）退群客户总数：筛选时间范围内，退出群聊的微信用户数量</div>
				</div>
			</div>
		</el-card>
		<div v-else>
			<el-card shadow="never">
				<div class="theFlex">
					<el-button type="text" style="color:black">客户数据统计</el-button>
					<el-button type="text" style="margin:0px 20px;padding:0px" @click="jump(1)">查看企微客户列表>>></el-button>
				</div>
				<div style="margin:15px 0px;display:flex;flex-direction:row">
					<div style="font-size:14px;line-height:37px;margin-right:15px">时间:</div>
					<el-date-picker popper-class="pickeroptClass" @change="changetime1" v-model="timeValue1" :clearable="false"
						type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" :picker-options="pickerOptions1">
					</el-date-picker>
				</div>
				<div class="blue-box" v-loading="blueloading1">
					<div style="text-align:center;margin:10px 0px">
						<div style="font-size:18px;font-weight:bold">
							{{allclientNum1}}
							<el-tooltip effect="dark" content="已经成为企业微信客户的人数，1个客户添加多个员工企业微信，计1个客户" placement="top">
								<i class="el-icon-warning-outline"></i>
							</el-tooltip>
						</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">全部客户数</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:10px 0px">
						<div style="font-size:18px;font-weight:bold">
							{{newclientNum1}}
							<el-tooltip effect="dark" content="筛选时间范围内，员工企业微信和客户微信添加成为好友，计为新增好友关系，1个客户添加多个员工企业微信，计多个新增" placement="top">
								<i class="el-icon-warning-outline"></i>
							</el-tooltip>
						</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">新增好友关系</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:10px 0px">
						<div style="font-size:18px;font-weight:bold">
							{{lossclientNum1}}
							<el-tooltip effect="dark" content="筛选时间范围内，客户删除员工企业微信的数量，计为流失好友关系，1个客户删除多个员工企业微信，计多个流失" placement="top">
								<i class="el-icon-warning-outline"></i>
							</el-tooltip>
						</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">流失好友关系</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:10px 0px">
						<div style="font-size:18px;font-weight:bold">
							{{netclientNum1}}
							<el-tooltip effect="dark" content="筛选时间范围内，新增好友关系数-流失好友关系数" placement="top">
								<i class="el-icon-warning-outline"></i>
							</el-tooltip>
						</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">净增好友关系</div>
					</div>
				</div>
				<el-radio-group v-model="tabPosition1" style="margin-top:20px;" @change="radioChange1">
					<el-radio-button label="0">客户总数</el-radio-button>
					<el-radio-button label="1">新增好友关系</el-radio-button>
					<el-radio-button label="2">流失好友关系</el-radio-button>
					<el-radio-button label="3">净增好友关系</el-radio-button>
				</el-radio-group>
				<line-chart :info="data1" v-if="data1.length" v-loading="chartloading1" :tooltip='tooltip1' :id="'Chart1'"
					style="width:100%;height:300px"></line-chart>
			</el-card>
			<el-card shadow="never" style="margin-top:15px">
				<div class="theFlex">
					<el-button type="text" style="color:black">客户群统计</el-button>
					<el-button type="text" style="margin:0px 20px;padding:0px" @click="jump(2)">查看企微客户群列表>>></el-button>
				</div>
				<div style="margin:15px 0px;display:flex;flex-direction:row">
					<div style="font-size:14px;line-height:37px;margin-right:15px">时间:</div>
					<el-date-picker popper-class="pickeroptClass" @change="changetime2" v-model="timeValue2" :clearable="false"
						type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期"
						end-placeholder="结束日期" :picker-options="pickerOptions2">
					</el-date-picker>
				</div>
				<div class="blue-box" v-loading="blueloading2">
					<div style="text-align:center;margin:10px 0px">
						<div style="font-size:18px;font-weight:bold">
							{{allclientNum2}}
							<el-tooltip effect="dark" content="已经创建的企业微信客户群，并且未解散的数量" placement="top">
								<i class="el-icon-warning-outline"></i>
							</el-tooltip>
						</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">全部客户群数量</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:10px 0px">
						<div style="font-size:18px;font-weight:bold">
							{{newclientNum2}}
							<el-tooltip effect="dark" content="未解散的企业微信客户群中，群内成员是微信用户的客户数量" placement="top">
								<i class="el-icon-warning-outline"></i>
							</el-tooltip>
						</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">群内客户总数</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:10px 0px">
						<div style="font-size:18px;font-weight:bold">
							{{lossclientNum2}}
							<el-tooltip effect="dark" content="筛选时间范围内，加入群聊的微信用户数量" placement="top">
								<i class="el-icon-warning-outline"></i>
							</el-tooltip>
						</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">入群客户总数</div>
					</div>
					<div style="width:1px;height:30px;background:rgba(180,180,180,0.5);margin-top:15px"></div>
					<div style="text-align:center;margin:10px 0px">
						<div style="font-size:18px;font-weight:bold">
							{{netclientNum2}}
							<el-tooltip effect="dark" content="筛选时间范围内，退出群聊的微信用户数量" placement="top">
								<i class="el-icon-warning-outline"></i>
							</el-tooltip>
						</div>
						<div style="font-size:14px;color:rgb(180,180,180);margin-top:5px">退群客户总数</div>
					</div>
				</div>
				<el-radio-group v-model="tabPosition2" style="margin-top:20px;" @change="radioChange2">
					<el-radio-button label="0">全部客户群数</el-radio-button>
					<el-radio-button label="1">群内客户总数</el-radio-button>
					<el-radio-button label="2">入群客户总数</el-radio-button>
					<el-radio-button label="3">退群客户总数</el-radio-button>
				</el-radio-group>
				<line-chart :info="data2" v-if="data2.length" :tooltip='tooltip2' v-loading="chartloading2" :id="'Chart2'"
					style="width:100%;height:300px"></line-chart>
			</el-card>
		</div>
	</div>
</template>

<script>
	import lineChart from '@/components/Charts/qyWechatChart'
	import config from '@/config/index'
	import {
		customerStatis,
		groupStatis,
	} from "@/api/TurnTomySelf"
	import {
		qyweixinsuiteauthinfo
	} from "@/api/sv1.0.0"
	export default {
		components: {
			lineChart,
		},
		data() {
			return {
				goUrls: config.GO_URL,
				timeValue1: [],
				timeValue2: [],
				//过去7天30天 算上今天共30天  所以-6 -29
				pickerOptions1: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今日',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							// start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '昨日',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24);
							end.setTime(end.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '本周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 1));
							// console.log(start.getDay())
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '上周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 1 + 7));
							end.setTime(end.getTime() - 3600 * 1000 * 24 * end.getDay());
							// console.log(start.getDay())
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '过去7天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '过去30天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				pickerOptions2: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今日',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							// start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '昨日',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24);
							end.setTime(end.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '本周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 1));
							// console.log(start.getDay())
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '上周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * (start.getDay() - 1 + 7));
							end.setTime(end.getTime() - 3600 * 1000 * 24 * end.getDay());
							// console.log(start.getDay())
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '过去7天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '过去30天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				tabPosition1: 0,
				tabPosition2: 0,
				allclientNum1: 0, //全部客户
				allclientNum2: 0,
				newclientNum1: 0, //新增
				newclientNum2: 0,
				lossclientNum1: 0, //流失
				lossclientNum2: 0,
				netclientNum1: 0, //净增
				netclientNum2: 0,
				data1: [],
				data2: [],
				tooltip1: '全部客户数',
				tooltip2: '客户群总数',
				chartloading1: false,
				chartloading2: false,
				blueloading1: false,
				blueloading2: false,

				isauthorization: true,
			}
		},
		created() {
			this.Isqyweixinsuiteauthinfo()
			// this.getToday()
			// this.getcustomerStatis()
			// this.getgroupStatis()
		},
		methods: {
			jumpQiDian() {
				this.$router.push({
					path: '/saleChannel/qyWechat'
				})
				// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#':'' 
				// let url = this.goUrls + headsUrls + '/saleChannel/qyWechat'
				// window.open(url)
			},
			//查看是否配置企业微信
			async Isqyweixinsuiteauthinfo() {
				this.pageloading = true
				try {
					let result = await qyweixinsuiteauthinfo({})
					if (result.IsSuccess) {
						// console.log(result.Result)
						this.isauthorization = result.Result.IsFinishQyWeixinSuiteStp
						if (this.isauthorization) {
							this.getToday()
							this.getcustomerStatis()
							this.getgroupStatis()
						}
					}
				} finally {
					this.pageloading = false
				}
			},
			getToday() {
				let end = new Date()
				let start = new Date()
				this.timeValue1 = [end, start]
				this.timeValue2 = [end, start]
			},
			changetime1(e) {
				// console.log(e)
				if (!e) {
					this.$message.error('时间最多可选择30天')
				} else {
					this.getcustomerStatis()
				}
			},
			changetime2(e) {
				if (!e) {
					this.$message.error('时间最多可选择30天')
				} else {
					this.getgroupStatis()
				}
			},
			//跳转客户客户群列表
			jump(e) {
				// console.log(e)
				let route = ''
				if (e == 1) {
					route = '/Customer/qyCustomerManage'
				} else if (e == 2) {
					route = '/Customer/clientBase/clientGroupList'
				}

				this.$router.push({
					path: route
				})
				// let productId = item.ProductId ? item.ProductId : 0
				// let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				// let url = this.goUrls + headsUrls + route
				// window.open(url)
			},
			radioChange1(e) {
				if (e == 0) {
					this.tooltip1 = '客户总数'
				} else if (e == 1) {
					this.tooltip1 = '新增好友关系'
				} else if (e == 2) {
					this.tooltip1 = '流失好友关系'
				} else if (e == 3) {
					this.tooltip1 = '净增好友关系'
				}
				this.getcustomerStatis()
			},
			radioChange2(e) {
				if (e == 0) {
					this.tooltip2 = '客户群总数'
				} else if (e == 1) {
					this.tooltip2 = '群内客户总数'
				} else if (e == 2) {
					this.tooltip2 = '入群客户总数'
				} else if (e == 3) {
					this.tooltip2 = '退群客户总数'
				}
				this.getgroupStatis()
			},
			changedate(time) {
				if (time) {
					let year = time.getFullYear()
					let month = time.getMonth() + 1 >= 10 ? time.getMonth() + 1 : "0" + (time.getMonth() + 1)
					let date = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate()
					return (year + '-' + month + '-' + date)
				}
			},
			async getcustomerStatis() {
				this.chartloading1 = true
				this.blueloading1 = true
				let data = {
					StartTime: this.changedate(this.timeValue1[0]),
					EndTime: this.changedate(this.timeValue1[1]),
				}
				// console.log(data)
				let result = await customerStatis(data)
				if (result.IsSuccess) {
					this.data1 = []
					this.allclientNum1 = 0
					this.newclientNum1 = 0
					this.lossclientNum1 = 0
					this.netclientNum1 = 0
					// console.log(result.Result)
					result.Result.map((v) => {
						this.allclientNum1 = v.AllCustomerCount
						this.newclientNum1 += v.NewFriendsCount
						this.lossclientNum1 += v.LosingFriendsCount
						this.netclientNum1 += v.NetGrowthFriendsCount
						let msg = {
							value: '',
							label: v.StatisDate.substring(5, 10),
						}
						// console.log(v.StatisDate.substring(5,10))
						if (this.tabPosition1 == 0) {
							msg.value = v.AllCustomerCount
						} else if (this.tabPosition1 == 1) {
							msg.value = v.NewFriendsCount
						} else if (this.tabPosition1 == 2) {
							msg.value = v.LosingFriendsCount
						} else if (this.tabPosition1 == 3) {
							msg.value = v.NetGrowthFriendsCount
						}
						this.data1.push(msg)
						return
					})
					this.chartloading1 = false
					this.blueloading1 = false
				}
			},
			async getgroupStatis() {
				this.chartloading2 = true
				this.blueloading1 = true
				let data = {
					StartTime: this.changedate(this.timeValue2[0]),
					EndTime: this.changedate(this.timeValue2[1]),
				}
				let result = await groupStatis(data)
				if (result.IsSuccess) {
					this.data2 = []
					this.allclientNum2 = 0
					this.newclientNum2 = 0
					this.lossclientNum2 = 0
					this.netclientNum2 = 0
					// console.log(result.Result)
					result.Result.map((v) => {
						this.allclientNum2 = v.QyWeixinGroupCount
						this.newclientNum2 = v.QyWeixinGroupCustomerCount
						this.lossclientNum2 += v.QyWeixinGroupAddCustomerCount
						this.netclientNum2 += v.QyWeixinGroupWithDrawCustomerCount
						let msg = {
							value: '',
							label: v.StatisDate.substring(5, 10),
						}
						if (this.tabPosition2 == 0) {
							msg.value = v.QyWeixinGroupCount
						} else if (this.tabPosition2 == 1) {
							msg.value = v.QyWeixinGroupCustomerCount
						} else if (this.tabPosition2 == 2) {
							msg.value = v.QyWeixinGroupAddCustomerCount
						} else if (this.tabPosition2 == 3) {
							msg.value = v.QyWeixinGroupWithDrawCustomerCount
						}
						this.data2.push(msg)
						return
					})
					this.chartloading2 = false
					this.blueloading1 = false
				}
			}
		}
	}
</script>

<style scoped lang="less">
	.theFlex {
		display: flex;
		flex-direction: row;
		/* flex-wrap: wrap; */
	}

	.blue-box {
		border: 1px solid #409EFF;
		background: #FBFDFF;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	
	.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}
	
	.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 15px;
		background-color: #f8f8f9;
		
		
	
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}
</style>
<style>
	.pickeroptClass.el-picker-panel {
		left: 298px !important
	}

	.pickeroptClass.el-popper .popper__arrow::after {
		left: -250px !important
	}
</style>
